import ApiService from "@/core/services/api.service";

// action types
export const UPDATE_PERSONAL_INFO = "updateUserPersonalInfo";
export const UPDATE_ACCOUNT_INFO = "updateUserAccountInfo";
export const UPDATE_LOCAL_STORAGE_DATA = "updateUserPersonalFromLocalStorage";
export const UPDATE_PASSWORD = "updatePassword";

// mutation types
export const SET_PERSONAL_INFO = "setPersonalInfo";
export const SET_ACCOUNT_INFO = "setAccountInfo";
export const SET_PERSONAL_INFO_FROM_LOCAL_STORAGE =
  "setUserPersonalInfoFromLocalStorage";

const state = {
  user_personal_info: {
    usuarioID: 0,
    rut: "",
    photo: "",
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    perfil: "",
    perfil_id: 0,
    email: "",
    phone: "",
    mandanteID: 0,
    mandante: "",
  },
  user_account_info: {
    username: "nick84",
    email: "nick.watson@loop.com",
    language: "English",
    time_zone: "(GMT-11:00) Midway Island",
    communication: {
      email: true,
      sms: true,
      phone: false,
    },
    verification: true,
  },
};

const getters = {
  currentUserPersonalInfo(state) {
    return state.user_personal_info;
  },

  currentUserAccountInfo(state) {
    return state.user_account_info;
  },
};

const actions = {
  [UPDATE_PERSONAL_INFO](context, payload) {
    context.commit(SET_PERSONAL_INFO, payload);
  },
  [UPDATE_ACCOUNT_INFO](context, payload) {
    context.commit(SET_ACCOUNT_INFO, payload);
  },
  [UPDATE_LOCAL_STORAGE_DATA](context) {
    context.commit(SET_PERSONAL_INFO_FROM_LOCAL_STORAGE);
  },
  async [UPDATE_PASSWORD]({ getters }, payload) {
    const user = getters.currentUserPersonalInfo;
    try {
      const { data } = await ApiService.post("users/password/change", {
        id: Number(user.usuarioID),
        ...payload,
      });

      return data;
    } catch (error) {
      console.error(error);
    }
  },
};

const mutations = {
  [SET_PERSONAL_INFO](state, user_personal_info) {
    state.user_personal_info = user_personal_info;

    window.localStorage.setItem(
      "id_localstorage_usuarioID",
      state.user_personal_info.usuarioID
    );
    window.localStorage.setItem(
      "id_localstorage_nombres",
      state.user_personal_info.nombres
    );
    window.localStorage.setItem(
      "id_localstorage_apellido_paterno",
      state.user_personal_info.apellido_paterno
    );
    window.localStorage.setItem(
      "id_localstorage_apellido_materno",
      state.user_personal_info.apellido_materno
    );
    window.localStorage.setItem(
      "id_localstorage_phone",
      state.user_personal_info.phone
    );
    window.localStorage.setItem(
      "id_localstorage_photo",
      state.user_personal_info.photo
    );
    window.localStorage.setItem(
      "id_localstorage_email",
      state.user_personal_info.email
    );
    window.localStorage.setItem(
      "id_localstorage_rut",
      state.user_personal_info.rut
    );
    window.localStorage.setItem(
      "id_localstorage_perfil",
      state.user_personal_info.perfil
    );
    window.localStorage.setItem(
      "id_localstorage_perfil_id",
      Number(state.user_personal_info.perfil_id)
    );
    window.localStorage.setItem(
      "id_localstorage_mandante",
      state.user_personal_info.mandante
    );
    window.localStorage.setItem(
      "id_localstorage_mandanteID",
      state.user_personal_info.mandanteID
    );
  },
  [SET_PERSONAL_INFO_FROM_LOCAL_STORAGE](state) {
    state.user_personal_info.usuarioID = window.localStorage.getItem(
      "id_localstorage_usuarioID"
    );
    state.user_personal_info.nombres = window.localStorage.getItem(
      "id_localstorage_nombres"
    );
    state.user_personal_info.apellido_paterno = window.localStorage.getItem(
      "id_localstorage_apellido_paterno"
    );
    state.user_personal_info.apellido_materno = window.localStorage.getItem(
      "id_localstorage_apellido_materno"
    );
    state.user_personal_info.phone = window.localStorage.getItem(
      "id_localstorage_phone"
    );
    state.user_personal_info.photo = window.localStorage.getItem(
      "id_localstorage_photo"
    );
    state.user_personal_info.email = window.localStorage.getItem(
      "id_localstorage_email"
    );
    state.user_personal_info.rut = window.localStorage.getItem(
      "id_localstorage_rut"
    );
    state.user_personal_info.perfil = window.localStorage.getItem(
      "id_localstorage_perfil"
    );
    state.user_personal_info.perfil_id = window.localStorage.getItem(
      "id_localstorage_perfil_id"
    );
    state.user_personal_info.mandante = window.localStorage.getItem(
      "id_localstorage_mandante"
    );
    state.user_personal_info.mandanteID = window.localStorage.getItem(
      "id_localstorage_mandanteID"
    );
  },
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

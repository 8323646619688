import ApiService from "@/core/services/api.service";

// action types
export const GENERATE_JOBBERS_LIQUIDATIONS = "generateJobbersLiquidations";
export const GET_LIQUIDATIONS = "getLiquidations";
export const GET_LIQUIDATION_DETAIL = "getLiquidationDetail";
export const GET_LIQUIDATION = "getLiquidation";

// mutation types

const state = {};

const getters = {};

const actions = {
  //eslint-disable-next-line no-unused-vars
  [GENERATE_JOBBERS_LIQUIDATIONS](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("liquidation/generateLiquidations", {
        jobbers: aData.jobbers,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_LIQUIDATIONS](context) {
    return new Promise((resolve) => {
      ApiService.post("liquidation/getLiquidations")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_LIQUIDATION_DETAIL](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("liquidation/getLiquidationDetail", {
        liquidation: aData.liquidation,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_LIQUIDATION](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("liquidation/getLiquidation", {
        liquidation: aData.liquidation,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};

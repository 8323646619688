/* eslint-disable no-unused-vars*/
import ApiService from "@/core/services/api.service";

// action types
export const GET_JOBBERS = "getJobbers";
export const NEW_DAILY_MESSAGE = "newDailyMessage";
export const GET_DAILY_MESSAGES_BY_JOBBER_ID = "getDailyMessagesByJobberID";
export const GET_JOBBERS_COMUNAS = "getJobbersComunas";
export const MASSIVE_JOBBER_MESSAGE = "massiveJobberMessage";
export const GET_CAMPAIGN_DAILY_MESSAGES_BY_JOBBER_ID =
  "getCampaignDailyMessagesByJobberID";
export const GET_USUARIO_GRUPOS = "getUsuarioGrupos";
export const GET_COMPETENCIAS = "getCompetencias";
export const GET_CAPACITACIONES = "getCapacitaciones";
export const GET_ANTECEDENTES = "getAntecedentes";
export const GET_JOBBERS_FILTERED = "getJobbersFiltered";
export const GET_ALL_JOBBER_DATA = "getAllJobberData";
export const GET_USUARIO_ESTADOS = "getUsuarioEstados";
export const GET_USUARIO_NIVELES_ESTUDIO = "getUsuarioNivelesEstudio";
export const GET_USUARIO_PROFESIONES = "getUsuarioProfesiones";
export const GET_USUARIO_BANCOS = "getUsuarioBancos";
export const GET_USUARIO_TIPO_CUENTAS = "getUsuarioTiposCuenta";
export const GET_USUARIO_CUENTAS = "getUsuarioCuentas";
export const SAVE_JOBBER_DATA = "saveJobberData";
export const NEW_COMUNA_JOBBER = "newComunaJobber";
export const NEW_COMPETENCIA_JOBBER = "newCompetenciaJobber";
export const NEW_CAPACITACION_JOBBER = "newCapacitacionJobber";
export const NEW_ANTECEDENTE_JOBBER = "newAntecedenteJobber";
export const NEW_CUENTA_JOBBER = "newCuentaJobber";
export const DELETE_DETALLE_JOBBER = "deleteDetalleJobber";
export const GET_VIDEOS = "getVideos";
export const GET_VIDEOS_GRUPOS = "getVideosGrupos";
export const GET_COVERAGE = "getCoverage";
export const GET_USUARIOS = "getUsuarios";
export const GET_PROSPECTOS_JOBBERS = "getProspectosJobbers";
export const GET_ANTECEDENTES_PROSPECTOS_JOBBERS =
  "getAntecedentesProspectosJobbers";
export const POST_ENVIO_RESPUESTA_PROSPECCION_JOBBER =
  "postEnvioRespuestaProspeccionJobber";
export const GET_RECURRENCIAS_PAGOS = "getRecurrenciasPago";
export const REASSIGN = "reassign";
export const CLEAN_JOBBER_IMEI = "cleanJobberImei";

// mutation types

const state = {};

const getters = {};

const actions = {
  [GET_JOBBERS](context, iEstadoID) {
    return new Promise((resolve) => {
      ApiService.post("users/getJobbers", { estado: iEstadoID })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [NEW_DAILY_MESSAGE](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("users/createDailyMessage", {
        jobber: aData.iJobberID,
        creador: aData.iCreatorID,
        fecha: aData.sFecha,
        mensaje: aData.sMensaje,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_DAILY_MESSAGES_BY_JOBBER_ID](context, iJobberID) {
    return new Promise((resolve) => {
      ApiService.post("users/getDailyMessages", { jobber: iJobberID })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_JOBBERS_COMUNAS](context, iMandanteID) {
    return new Promise((resolve) => {
      ApiService.post("users/getJobbersComunas", { mandante: iMandanteID })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_USUARIO_GRUPOS]() {
    return new Promise((resolve) => {
      ApiService.post("users/getUsuarioGrupos")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_COMPETENCIAS]() {
    return new Promise((resolve) => {
      ApiService.post("users/getCompetencias")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_CAPACITACIONES]() {
    return new Promise((resolve) => {
      ApiService.post("users/getCapacitaciones")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_ANTECEDENTES]() {
    return new Promise((resolve) => {
      ApiService.post("users/getAntecedentes")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_JOBBERS_FILTERED](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("users/getJobbersFiltered", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_ALL_JOBBER_DATA](context, iJobberID) {
    return new Promise((resolve) => {
      ApiService.post("users/getAllJobberData", { jobber: iJobberID })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_USUARIO_ESTADOS]() {
    return new Promise((resolve) => {
      ApiService.post("users/getUsuarioEstados")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_USUARIO_NIVELES_ESTUDIO]() {
    return new Promise((resolve) => {
      ApiService.post("users/getUsuarioNivelesEstudio")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_USUARIO_PROFESIONES]() {
    return new Promise((resolve) => {
      ApiService.post("users/getUsuarioProfesiones")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_USUARIO_BANCOS]() {
    return new Promise((resolve) => {
      ApiService.post("users/getUsuarioBancos")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_USUARIO_TIPO_CUENTAS]() {
    return new Promise((resolve) => {
      ApiService.post("users/getUsuarioTiposCuenta")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_USUARIO_CUENTAS](context, iJobberID) {
    return new Promise((resolve) => {
      ApiService.post("users/getUsuarioCuentas", { jobber: iJobberID })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [SAVE_JOBBER_DATA](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("users/saveJobberData", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [NEW_COMUNA_JOBBER](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("users/newComunaJobber", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [NEW_COMPETENCIA_JOBBER](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("users/newCompetenciaJobber", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [NEW_CAPACITACION_JOBBER](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("users/newCapacitacionJobber", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [NEW_ANTECEDENTE_JOBBER](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("users/newAntecedenteJobber", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [NEW_CUENTA_JOBBER](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("users/newCuentaJobber", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [MASSIVE_JOBBER_MESSAGE](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("users/createMassiveMessages", {
        jobbers: aData.aJobbers,
        creador: aData.iCreatorID,
        mensaje: aData.sMensaje,
        fecha: aData.sFecha,
        campaign: aData.iCampaignID,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_CAMPAIGN_DAILY_MESSAGES_BY_JOBBER_ID](context, iCampaignID) {
    return new Promise((resolve) => {
      ApiService.post("users/getCampaignDailyMessages", {
        campaign: iCampaignID,
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [DELETE_DETALLE_JOBBER](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("users/deleteDetalleJobber", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_VIDEOS]() {
    return new Promise((resolve) => {
      ApiService.post("users/getVideos", {})
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_VIDEOS_GRUPOS]() {
    return new Promise((resolve) => {
      ApiService.post("users/getVideosGrupos", {})
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_COVERAGE](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("users/getCoverage", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_USUARIOS](context, iMandanteID) {
    return new Promise((resolve) => {
      ApiService.post("users/getUsuarios", { mandante: iMandanteID })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_PROSPECTOS_JOBBERS](context) {
    return new Promise((resolve) => {
      ApiService.post("users/getProspectosJobbers")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_ANTECEDENTES_PROSPECTOS_JOBBERS](context, iProspectoID) {
    return new Promise((resolve) => {
      ApiService.post("users/getAntecedentesProspectoJobber", {
        prospecto: iProspectoID,
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [POST_ENVIO_RESPUESTA_PROSPECCION_JOBBER](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("users/envioRespuestaProspeccionJobber", {
        prospecto: aData.prospecto,
        aprobado: aData.aprobar,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_RECURRENCIAS_PAGOS](context) {
    return new Promise((resolve) => {
      ApiService.post("users/getRecurrenciasPago", {})
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [REASSIGN](_, oData) {
    const { type, ...body } = oData;
    return new Promise((resolve) => {
      ApiService.post(`users/reassign/${type}`, body)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  async [CLEAN_JOBBER_IMEI](context, { id }) {
    const { data } = await ApiService.get(`users/jobbers/${id}/cleanImei`);
    return data;
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};

import ApiService from "@/core/services/api.service";
import { stringifyRequestQuery } from "@/helpers/helper";

const route = "addressNormalization";

// action types
export const GET_NORMALIZATIONS = "query";
export const SAVE_NORMALIZATION = "";
export const DOWNLOAD_ORIGINAL_NORMALIZATION = "download/original";
export const DOWNLOAD_NORMALIZATION = "download/normalized";
export const GET_TYPES = "types";

const state = {};

const getters = {};

const actions = {
  [GET_NORMALIZATIONS](_, { state, mandante }) {
    const query = stringifyRequestQuery(state);
    return new Promise((resolve) => {
      ApiService.get(
        `${route}/mandantes/${mandante}/${GET_NORMALIZATIONS}/${query}`
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          console.log(e);
          resolve();
        });
    });
  },
  async [SAVE_NORMALIZATION](_, body) {
    try {
      const { data: result } = await ApiService.post(
        `${route}/${SAVE_NORMALIZATION}`,
        body
      );
      return result;
    } catch (error) {
      console.log("Error al guardar normalización", error);
      return;
    }
  },
  [DOWNLOAD_NORMALIZATION](_, id) {
    return new Promise((resolve) => {
      ApiService.post(`${route}/${DOWNLOAD_NORMALIZATION}`, {
        normalizacion: id,
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [DOWNLOAD_ORIGINAL_NORMALIZATION](_, id) {
    return new Promise((resolve) => {
      ApiService.post(`${route}/${DOWNLOAD_ORIGINAL_NORMALIZATION}`, {
        normalizacion: id,
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  async [GET_TYPES]() {
    try {
      const { data } = await ApiService.get(`${route}/${GET_TYPES}`);
      return data;
    } catch (error) {
      console.log(error);
      return [];
    }
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};

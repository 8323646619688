import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
//import MockService from "@/core/mock/mock.service";
import underscore from "vue-underscore";
import UUID from "vue-uuid";
import { VERIFY_AUTH } from "@/core/services/store/auth.module";
import { UPDATE_LOCAL_STORAGE_DATA } from "@/core/services/store/profile.module";
import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import Swal from "sweetalert2";

Vue.config.productionTip = false;
Vue.use(underscore);
Vue.use(UUID);

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/core/plugins/formvalidation";

// API service init
ApiService.init();

// Remove this to disable mock API
//MockService.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch(VERIFY_AUTH)])
    .then(next)
    .catch(() => {
      Swal.fire({
        title: `Sesión expirada`,
        text: `Su sesión ha expirado. Por favor, autentiquese nuevamente.`,
        icon: "warning",
      });
      router.push({ name: "login" });
    });
  store.dispatch(UPDATE_LOCAL_STORAGE_DATA);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
  watch: {
    $route(to, from) {
      if (
        to.meta.reload &&
        from.meta.reload &&
        to !== from &&
        from.path != "/"
      ) {
        window.location.reload();
      }
    },
  },
}).$mount("#app");

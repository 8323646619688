import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const FORGOT = "forgot";
export const GET_USER_TOKEN = "getUsuarioToken";
export const PASSWORD = "change-password";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user[0];
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("users/login", credentials)
        .then(({ data }) => {
          if (data.result) {
            context.commit(SET_AUTH, data.data);
            resolve(data);
          } else {
            resolve({
              result: false,
              data: [],
              message: data.message,
            });
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  async [VERIFY_AUTH](context) {
    if (!JwtService.getToken()) {
      context.commit(PURGE_AUTH);
      return;
    }

    ApiService.setHeader();

    try {
      const { data } = await ApiService.post("users/validateSession");
      if (data.result === false) {
        context.commit(PURGE_AUTH);
        throw new Error("Invalid Session.");
      }
    } catch (error) {
      context.commit(SET_ERROR, error);
      throw error;
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [FORGOT](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("users/forgot", credentials)
        .then(({ data }) => {
          if (data.result) {
            resolve(data);
          } else {
            resolve({
              result: false,
              data: [],
              message: data.message,
            });
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [GET_USER_TOKEN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("users/getUsuarioToken", credentials)
        .then(({ data }) => {
          if (data.result) {
            resolve(data);
          } else {
            resolve({
              result: false,
              data: [],
              message: data.message,
            });
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [PASSWORD](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post("users/change-password", credentials)
        .then(({ data }) => {
          if (data.result) {
            resolve(data);
          } else {
            resolve({
              result: false,
              data: [],
              message: data.message,
            });
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, aData) {
    state.isAuthenticated = true;
    state.user = aData.user;
    state.errors = {};
    JwtService.saveToken(aData.token);
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

import ApiService from "@/core/services/api.service";

// action types
export const GET_MANDANTES = "getMandantes";
export const GET_CODIGOS = "getCodigos";
export const GET_REPORTES_POWER_BI = "getReportesPowerBi";
export const GET_POWER_BI_REPORT = "getPowerBIReport";
export const SAVE_POWER_BI_REPORT = "savePwerBiReport";
export const UPDATE_POWER_BI_REPORT = "updatePwerBiReport";
export const DELETE_POWER_BI_REPORT = "deletePwerBiReport";

// mutation types

const state = {};

const getters = {};

const actions = {
  [GET_MANDANTES]() {
    return new Promise((resolve) => {
      ApiService.post("mandantes/getMandantes")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_CODIGOS](context, iMandanteID) {
    return new Promise((resolve) => {
      ApiService.post("mandantes/getCodigos", { mandante: iMandanteID })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_REPORTES_POWER_BI](context, iMandanteID) {
    return new Promise((resolve) => {
      ApiService.post("mandantes/getReportesPowerBi", { mandante: iMandanteID })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_POWER_BI_REPORT](_, reportId) {
    return new Promise((resolve) => {
      ApiService.post("mandantes/getPowerBIReport", { reportId })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  async [SAVE_POWER_BI_REPORT](
    _,
    { mandanteId, name, description, workspaceId, reportId }
  ) {
    const result = await ApiService.post("mandantes/savePowerBIReport", {
      mandanteId,
      name,
      description,
      workspaceId,
      reportId,
    });

    return result.data;
  },

  async [UPDATE_POWER_BI_REPORT](
    _,
    { id, name, description, workspaceId, reportId, visible }
  ) {
    const result = await ApiService.post("mandantes/updatePowerBIReport", {
      id,
      name,
      description,
      workspaceId,
      reportId,
      visible,
    });

    return result.data;
  },

  async [DELETE_POWER_BI_REPORT](_, { id }) {
    const result = await ApiService.post("mandantes/deletePowerBIReport", {
      id,
    });

    return result.data;
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};

import ApiService from "@/core/services/api.service";

// action types
export const GET_TASK_TRACING_CAMPAIGN_DATA = "getTaskTracingByCampaign";

// mutation types

const state = {};

const getters = {};

const actions = {
  [GET_TASK_TRACING_CAMPAIGN_DATA](context, iCampaignID) {
    return new Promise((resolve) => {
      ApiService.post("tracing/getTaskTracingByCampaign", {
        campaign: iCampaignID,
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};

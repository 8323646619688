import ApiService from "@/core/services/api.service";
import { stringifyRequestQuery } from "@/helpers/helper";

// action types
export const GET_TASKS_MANDANTE = "getTasksMandante";
export const GET_ESTADOS_TASK = "GetEstadosTask";
export const GET_JOBBERS_MANDANTE = "getJobbersMandante";
export const GET_ALL_TASK_DATA = "getAllTaskData";
export const POST_JOBBER_ASSIGNMENT = "postJobberAssignment";
export const GET_FILE = "getFile";
export const GET_TASK_CAMPAIGN = "getTasksByCampaign";
export const GET_TASK_FILTERED = "getTasksFiltered";
export const GET_TASKS_BY_JOBBER = "getTasksByJobber";
export const GET_TASK_FILTERED_CAMPAIGN_JOBBER =
  "getTasksFilteredByCampaignAndJobber";
export const UNASSIGN_JOB_JOBBER = "unassignJobJobber";
export const GET_COMPLEATED_JOBS = "getCompleatedJobs";
export const GET_TASKS_EXPORT = "getTasksExport";
export const SAVE_TASK_DATA = "saveTaskData";
export const GENERATE_CERTIFICATE = "generateCertificate";
export const GET_MANAGED_TASKS_FOR_LIQUIDATION =
  "getManagedTasksForLiquidation";
export const CHANGE_TASKS_STATE = "changeTasksState";

// mutation types

const state = {};

const getters = {};

const actions = {
  //eslint-disable-next-line no-unused-vars
  async [GET_TASKS_MANDANTE](
    context,
    { mandante, campaign, jobber, job, state, managmentDate, queryParams }
  ) {
    const query = stringifyRequestQuery(queryParams);
    const { data: result } = await ApiService.post(
      `tasks/getTasksMandante/${query}`,
      {
        mandante,
        campaign,
        jobber,
        job,
        state,
        managmentDate,
      }
    );
    return result;
  },
  [GET_ESTADOS_TASK]() {
    return new Promise((resolve) => {
      ApiService.post("tasks/getEstadosTask", {})
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_JOBBERS_MANDANTE](context, iMandanteID) {
    return new Promise((resolve) => {
      ApiService.post("tasks/getJobbersMandante", { mandante: iMandanteID })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [POST_JOBBER_ASSIGNMENT](context, aJobs) {
    return new Promise((resolve) => {
      ApiService.post("tasks/postJobberAssignment", { jobs: aJobs })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_ALL_TASK_DATA](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("tasks/getAllTaskData", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_FILE](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("tasks/getFile", {
        campaign: aData.campaign,
        fileName: aData.fileName,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_TASK_CAMPAIGN](context, iCampaignID) {
    return new Promise((resolve) => {
      ApiService.post("tasks/getTasksByCampaign", { campaign: iCampaignID })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_TASK_FILTERED](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("tasks/getTasksFiltered", aData)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_TASKS_BY_JOBBER](context, iJobberID) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getJobsByJobber", { jobber: iJobberID })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_TASK_FILTERED_CAMPAIGN_JOBBER](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("tasks/getTasksFilteredByCampaignAndJobber", {
        campaign: aData.iCampaignID,
        jobber: aData.iJobberID,
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [UNASSIGN_JOB_JOBBER](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("tasks/unassignJobJobber", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_COMPLEATED_JOBS](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("tasks/getCompleatedJobs", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_TASKS_EXPORT](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("tasks/getTasksExport", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [SAVE_TASK_DATA](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("tasks/saveTaskData", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GENERATE_CERTIFICATE](context, aTask) {
    return new Promise((resolve) => {
      ApiService.post("tasks/generateCertificate", { task: aTask.task })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [GET_MANAGED_TASKS_FOR_LIQUIDATION](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("tasks/getManagedTasksForLiquidation", {
        from: aData.from,
        to: aData.to,
        recurrencia: aData.recurrencia,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  //eslint-disable-next-line no-unused-vars
  [CHANGE_TASKS_STATE](context, aJobs) {
    return new Promise((resolve) => {
      ApiService.post("tasks/changeTasksState", { jobs: aJobs })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};

import ApiService from "@/core/services/api.service";
import { stringifyRequestQuery } from "@/helpers/helper";

// action types
export const GET_CAMPAIGNS_MANDANTE = "getCampaignsMandante";
export const GET_CAMPAIGN_TYPES = "getCampaignTypes";
export const NEW_CAMPAIGN_DATA = "newCampaignData";
export const UPDATE_CAMPAIGN_DATA = "updateCampaignData";
export const GET_CAMPAIGN_FIELDS_TYPES = "getCampaignFieldsTypes";
export const NEW_CAMPAIGN_FIELDS = "newCampaignFields";
export const GET_ALL_CAMPAIGN_DATA = "getAllCampaignData";
export const GET_CAMPAIGN_TREE_DATA_EXCEL = "getCampaignTreeDataExcel";
export const GET_ESTADOS_CAMPAIGN = "getEstadosCampaign";
export const GET_CAMPAIGN_JOBS_FINISHED_CAMPAIGN_DATA =
  "getCampaignJobsRealizedByCampaign";
export const GET_CAMPAIGNS_BY_JOBBER = "getCampaignsByJobber";
export const UPDATE_CAMPAIGN_SCHEDULE = "updateCampaignSchedule";
export const GET_CAMPAIGN_CERTIFICATE = "getCertificateByCampaign";
export const GET_GROUPS = "getGroups";
export const NEW_GROUP = "newGroup";
export const GET_CUSTOM_CERTFIEDS = "getCustomCertifieds";
export const UPDATE_GROUP = "updateGroup";
export const ADD_CAMPAIGN_GROUP = "addCampaignGroup";
export const DELETE_CAMPAIGN_GROUP = "removeCampaignGroup";
export const CONFIG_CUSTOM_CERTFIED = "configCustomCertified";
export const GET_ALL_CAMPAIGN_DATA_BY_SOME_CAMPAIGNS =
  "getAllCampaignDataBySomeCampaigns";
export const GET_DOCUMENTS = "getDocuments";
export const NEW_DOCUMENT = "newDocument";
export const DELETE_DOCUMENT = "deleteDocument";
export const GET_FIELDS_OPTIONS_CAMPAIGN_BY_JOBBER =
  "getFieldsOptionsCampaignByJobber";
export const UPDATE_END_DATE = "updateEndDate";

// mutation types

const state = {};

const getters = {};

const actions = {
  [GET_CAMPAIGNS_MANDANTE](context, params) {
    let mandante = "";
    let activas = "";
    if (typeof params !== "object" || params === null) {
      mandante = params;
      activas = 0;
    } else {
      mandante = params.mandante;
      activas = params.activas;
    }

    return new Promise((resolve) => {
      ApiService.post("campaigns/getCampaignsMandante", { mandante, activas })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_CAMPAIGN_TYPES]() {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getCampaignTypes", {})
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [NEW_CAMPAIGN_DATA](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/newCampaign", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [UPDATE_CAMPAIGN_DATA](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/updateCampaign", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_CAMPAIGN_FIELDS_TYPES]() {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getTiposCampos", {})
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [NEW_CAMPAIGN_FIELDS](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/createFields", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_ALL_CAMPAIGN_DATA](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getAllCampaignData", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_CAMPAIGN_TREE_DATA_EXCEL](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getCampaignTreeDataExcel", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_ESTADOS_CAMPAIGN](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getEstadosCampaign", aData)
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_CAMPAIGN_JOBS_FINISHED_CAMPAIGN_DATA](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getCampaignJobsRealizedByCampaign", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_CAMPAIGNS_BY_JOBBER](context, iJobberID) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getCampaignsByJobber", { jobber: iJobberID })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [UPDATE_CAMPAIGN_SCHEDULE](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/updateCampaignSchedule", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_CAMPAIGN_CERTIFICATE](context, iCampaignID) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getCertificateByCampaign", {
        campaign: iCampaignID,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_GROUPS](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getGroups", { mandante: aData.mandante })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [NEW_GROUP](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/createGroup", {
        mandante: aData.mandante,
        nombre: aData.nombre,
        descripcion: aData.descripcion,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_CUSTOM_CERTFIEDS](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getCertificatesByMandante", {
        mandante: aData.mandante,
      })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [UPDATE_GROUP](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/updateGroup", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [ADD_CAMPAIGN_GROUP](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/addCampaignGroup", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [DELETE_CAMPAIGN_GROUP](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/deleteCampaignGroup", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [CONFIG_CUSTOM_CERTFIED](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/configCustomCertificate", {
        campaign: aData.campaign,
        certificate: aData.certificate,
        config: aData.config,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_ALL_CAMPAIGN_DATA_BY_SOME_CAMPAIGNS](context, aData) {
    const { state, aCampaigns, fechaAsignacion, fechaGestion } = aData;
    const query = stringifyRequestQuery(state);
    return new Promise((resolve) => {
      ApiService.post(`campaigns/getAllCampaignDataBySomeCampaigns/${query}`, {
        campaigns: aCampaigns,
        fechaAsignacion,
        fechaGestion,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_DOCUMENTS](context, iCampaignID) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getDocuments", { campaign: iCampaignID })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [NEW_DOCUMENT](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/newDocument", {
        campaign: aData.campaign,
        name: aData.name,
        description: aData.description,
        base64: aData.base64,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [DELETE_DOCUMENT](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/deleteDocument", {
        campaign: aData.campaign,
        document: aData.document,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  [GET_FIELDS_OPTIONS_CAMPAIGN_BY_JOBBER](context, aData) {
    return new Promise((resolve) => {
      ApiService.post("campaigns/getFieldsOptionsCampaignByJobber", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
  async [UPDATE_END_DATE](context, payload) {
    const { data: result } = await ApiService.post(
      "campaigns/updateEndDate",
      payload
    );
    return result;
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};

import qs from "qs";
import config from "../../config";

export const getInfoXLSXCampaign = (campaign) => {
  return [
    ["ID Campaña", campaign.campaignID],
    ["Mandante", campaign.mandante],
    ["Campaña", campaign.nombre],
    ["Descripcion", campaign.descripcion],
    ["Tipo de Campaña", campaign.tipo],
    ["Costo", campaign.costoTarea],
    ["Estado", campaign.estado],
    ["Fecha Inicio", campaign.fechaInicio],
    ["Fecha Termino", campaign.fechaTermino],
    ["Dias Trascurridos", campaign.diasTranscurridos],
    ["Dias Totales", campaign.diasTotales],
    ["Tareas Realizadas", campaign.cantidadTareasRealizadas],
    ["Tareas Asignadas", campaign.cantidadTareasAsignadas],
    ["Tareas Totales", campaign.cantidadTareas],
  ];
};

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
export const stringifyRequestQuery = (state) => {
  const pagination = qs.stringify(state, {
    filter: ["page", "items_per_page"],
    skipNulls: true,
  });
  const sort = qs.stringify(state, {
    filter: ["sort", "order"],
    skipNulls: true,
  });
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, { filter: ["search"], skipNulls: true })
    : "";

  const filter = state.filter
    ? Object.entries(state.filter)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `filter_${obj[0]}=${obj[1]}`;
        })
        .join("&")
    : "";
  const report = qs.stringify(state, { filter: ["report"], skipNulls: true });

  const query = [pagination, sort, search, filter, report]
    .filter((f) => f)
    .join("&");
  // .toLowerCase()

  return query;
};

export function isNotEmpty(obj) {
  return obj !== undefined && obj !== null && obj !== "";
}

export function appendGoogleMapScript() {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.id = "map-api-script";
    const key = config.VUE_APP_GOOGLE_TOKEN;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${key}&&libraries=geometry,places&v=3`;
    document.body.appendChild(script);

    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = function () {
      resolve(true);
    };
  });
}

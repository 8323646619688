import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import campaign from "./campaign.module";
import task from "./task.module";
import jobber from "./jobber.module";
import mandante from "./mandante.module";
import tracing from "./tracing.module";
import global from "./global.module";
import liquidation from "./liquidation.module";
import setting from "./setting.module";
import address_normalization from "./address-normalization.module";
import comune from "./comune.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    campaign,
    task,
    jobber,
    mandante,
    tracing,
    global,
    liquidation,
    setting,
    address_normalization,
    comune,
  },
});

import ApiService from "@/core/services/api.service";

// action types
export const GET_WRONG_COMUNES = "getWrongComunes";

// mutation types

const state = {};

const getters = {};

const actions = {
  async [GET_WRONG_COMUNES]() {
    try {
      const { data } = await ApiService.get("comunes/wrongs");
      return data;
    } catch (error) {
      console.log("Error al obtener comunas erroneas", error);
      return [];
    }
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};

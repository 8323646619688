import ApiService from "@/core/services/api.service";

// action types
export const GET_MENU = "getMenu";
export const GET_INALIENABLE_CALENDAR = "getInalienableCalendar";
export const GET_REGIONES = "getRegiones";
export const GET_PERFILES = "getPerfiles";
export const GET_REGIONES_PROVINCIAS_COMUNAS = "getRegionesProvinciasComunas";

// mutation types

const state = {};

const getters = {};

const actions = {
  [GET_MENU](context, iUsuarioID) {
    return new Promise((resolve) => {
      ApiService.post("global/getMenu", { user: iUsuarioID })
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_INALIENABLE_CALENDAR]() {
    return new Promise((resolve) => {
      ApiService.post("global/getInalienableCalendar")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_REGIONES]() {
    return new Promise((resolve) => {
      ApiService.post("global/getRegiones")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_PERFILES]() {
    return new Promise((resolve) => {
      ApiService.post("global/getPerfiles")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [GET_REGIONES_PROVINCIAS_COMUNAS]() {
    return new Promise((resolve) => {
      ApiService.post("global/getRegionesProvinciasComunas")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};

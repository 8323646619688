import ApiService from "@/core/services/api.service";

// action types
export const GET_PARAMETROS = "getParametros";
export const SAVE_PARAMETER_DATA = "saveParameterData";

const state = {};

const getters = {};

const actions = {
  [GET_PARAMETROS]() {
    return new Promise((resolve) => {
      ApiService.post("settings/getParametros")
        .then(({ data }) => {
          resolve(data.data);
        })
        .catch(() => {});
    });
  },
  [SAVE_PARAMETER_DATA](_, aData) {
    return new Promise((resolve) => {
      ApiService.post("settings/saveParameterData", aData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(() => {});
    });
  },
};

const mutations = {};

export default {
  state,
  actions,
  mutations,
  getters,
};

import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  base: "/",
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/campaigns",
          name: "campaigns",
          component: () => import("@/view/pages/Campaigns.vue"),
        },
        {
          path: "/reassign",
          name: "reassign",
          component: () => import("@/view/pages/Reassign.vue"),
        },
        {
          path: "/campaigns/groups",
          name: "campaignsGroups",
          component: () => import("@/view/pages/CampaignGroups.vue"),
        },
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/view/pages/custom_pages/Profile.vue"),
        },
        {
          path: "/tasks",
          name: "tasks",
          component: () => import("@/view/pages/Tasks.vue"),
        },
        {
          path: "/prospeccion-jobbers",
          name: "prospeccion-jobbers",
          component: () => import("@/view/pages/ProspeccionJobbers.vue"),
        },
        {
          path: "/jobbers",
          name: "jobbers",
          component: () => import("@/view/pages/Jobbers.vue"),
          props: { modulo: "Jobber" },
          meta: {
            reload: true,
          },
        },
        {
          path: "/tracing",
          name: "tracing",
          component: () => import("@/view/pages/Tracing.vue"),
        },
        {
          path: "/jobber-coverage",
          name: "jobberCoverage",
          component: () => import("@/view/pages/JobberCoverage.vue"),
        },
        {
          path: "/coverage",
          name: "coverage",
          component: () => import("@/view/pages/Coverage.vue"),
        },
        {
          path: "/analisis",
          name: "analisis",
          component: () => import("@/view/pages/Analisis.vue"),
        },
        {
          path: "/videos",
          name: "videos",
          component: () => import("@/view/pages/Videos.vue"),
        },
        {
          path: "/managements",
          name: "managements",
          component: () => import("@/view/pages/Managements.vue"),
        },
        {
          path: "/tasks-export",
          name: "tasks-export",
          component: () => import("@/view/pages/TasksExport.vue"),
        },
        {
          path: "/users",
          name: "users",
          component: () => import("@/view/pages/Jobbers.vue"),
          props: { modulo: "Usuario" },
          meta: {
            reload: true,
          },
        },
        {
          path: "/liquidations/list",
          name: "liquidation_list",
          component: () =>
            import("@/view/pages/liquidations/ListLiquidations.vue"),
        },
        {
          path: "/liquidations/new",
          name: "create_liquidation",
          component: () =>
            import("@/view/pages/liquidations/CreateLiquidation.vue"),
        },
        {
          path: "/parameters",
          name: "parameters",
          component: () => import("@/view/pages/Parameters.vue"),
        },
        {
          path: "/address-normalization",
          name: "address-normalization",
          component: () => import("@/view/pages/AddressNormalization.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/Login"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/Login"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/Login"),
        },
        {
          name: "change-password",
          path: "/change-password/:token",
          component: () => import("@/view/pages/Login"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
